body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #ffffff;
}

.filterMenuStyleOverride {
  padding-right: 0 !important;
  overflow: visible !important;
}

.circleStyle {
  position: absolute;
  top: 50%;
  left: 50%;
}

.navigation-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.smartbanner-container{
  background: rgba(242, 242, 242);
}
.smartbanner-title{
  font-family: "Open Sans", sans-serif;
  font-size: 1.5em !important;
  color: rgba(77, 55, 7, 1) !important;
  font-weight: 700 !important;
  text-shadow: none ;
  text-transform: none;
  font-style: normal;
  line-height: 120% !important;
}
.smartbanner-author{
  font-family: sans-serif;
  font-size: 1.4em !important;
  font-weight: 500 !important;
  color: rgba(77, 55, 7, 1);
  text-shadow: none ;
  text-transform: none;
  font-style: normal;
  line-height: 120% !important;
  white-space: normal !important;
}
.smartbanner-description{
  display: none ;
}

/* Overriding foxit Progress bar styles for audio playlist */
.navigation-audio-player .progress-bar {
  background-color: #ffffff;
  line-height: inherit;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
    border: 2px solid #7E031A;
  }

  25% {
    margin-left: 0.5rem;
    border: 2px solid #7E031A;
  }

  75% {
    margin-left: -0.5rem;
    border: 2px solid #7E031A;
  }

  100% {
    margin-left: 0rem;
    border: 2px solid #7E031A;
  }
}

@media print {
  html,
  body {
    visibility: hidden;
  }
}

/* .MuiIconButton-root:hover {
  background-color: transparent;
} */
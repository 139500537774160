/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
* Glossary popup styles
*
* @file glossaryPopup.css
* @author Prithviraj K
*/

.glossary-audioIcon {
  cursor: pointer;
  float: right;
  height: 28px;
  width: 28px;
}

#glossary-popper {
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  padding: 15px;
  width: 400px;
  z-index: 99;
}

#glossary-popper::before {
  content: "";
  border: 15px solid transparent;
  position: absolute;
  left: 185px;
}

#glossary-popper[x-placement*="top"]::before {
  border-top-color: #f5f5f5;
  bottom: -30px;
}

#glossary-popper[x-placement*="bottom"]::before {
  border-bottom-color: #f5f5f5;
  top: -30px;
}

.glossary-term {
  margin: 0 0 5px 0;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  line-height: 1.22;
  letter-spacing: -0.5px;
  color: #252525;
  word-wrap: break-word;
}

.glossary-definition {
  word-break: break-word;
  line-height: 1.38;
  font-size: 16px;
  color: #252525;
  letter-spacing: -0.4px;
  margin: 5px 0;
  text-align: left;
}

.popup-backgroundImage .MuiDialog-paperWidthSm,
.marketing-popup-background .MuiDialog-paperWidthSm {
  max-width: 684px;
}

.popup-backgroundImage .MuiPaper-root {
  background-repeat: no-repeat;
  background-color: #F7F9FD;
  border-radius: 8px;
}

.swap-background .MuiPaper-root{
  background-size: 35% 150%;
  max-width: 600px;
}

.popup-backgroundImage .MuiDialogTitle-root, .popup-backgroundImage .MuiDialogContent-root {
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 30px;
  padding: 16px 24px 0px;
}

.marketing-popup-background .MuiDialogTitle-root, .marketing-popup-background .MuiDialogContent-root {
  margin-left: 60px;
  margin-right: 60px;
  padding: 16px 24px 0px;
  overflow: unset;
}

.popup-backgroundImage .alert_continue_button, .marketing-popup-background .marketing_continue_button {
  justify-content: center;
}

.popup-backgroundImage .alert_continue_button button,
.marketing-popup-background .marketing_continue_button button {
  background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
  border-radius: 40px;
  font-size: 14px;
  padding: 8px 15px;
 }

.popup-backgroundImage .MuiTypography-root {
  margin: 0 auto;
}

.marketing-popup-background .preference_ratio .MuiRadio-root.Mui-checked + span {
  font-family: Hind, Sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
}
.marketing-popup-background .preference_ratio .MuiFormControlLabel-label {
  padding: 9px;
}
.marketing-popup-background .MuiDialogContent-root:first-child {
  padding-top: 0px;
}
.marketing-popup-background .preference_ratio .MuiFormControlLabel-root {
  padding-top: 5px;
}
.marketing-popup-background .preference_ratio .MuiRadio-root {
  padding: 9px;
  font-family: Hind, Sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
}

.marketing-popup-background .MuiTypography-root h5 {
  font-family: Hind, Sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 120%;
  text-align: center;
  color: #333333;
}
.marketing-popup-background .MuiTypography-root h2 {
  font-family: TT Commons, Sans-serif;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  color: #333333;
}
.popup-backgroundImage .MuiTypography-root h3 {
font-family: TT Commons, Sans-serif;
font-size: 30px;
line-height: 33px;
letter-spacing: 0px;
text-align: center;
margin: 0px auto;
}

.popup-backgroundImage .MuiTypography-root p,
.marketing-popup-background .MuiTypography-root p {
  font-family: Hind, Sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 0;
}

.marketing-popup-background .preference_ratio .MuiRadio-colorSecondary.Mui-checked {
  color: #6A7070;
}
.marketing-popup-background .preference_ratio .MuiIconButton-colorSecondary:hover{
  background-color: transparent;
}
.marketing-popup-background .preference_ratio {
  margin: 0.5rem;
}

.marketing-popup-background .preference_ratio input[type="radio"] {
    position: absolute;
    opacity: 0;
}
.marketing-popup-background .preference_ratio input[type="radio"] + .radio-label:before {
        content: '';
        background: $color1;
        border-radius: 100%;
        border: 1px solid darken($color1, 25%);
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: 1em; 
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
.marketing-popup-background .preference_ratio input[type="radio"]:checked + .radio-label:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
}

.marketing-popup-background .preference_ratio input[type="radio"]:focus + .radio-label:before {
          outline: none;
          border-color: $color2;
}
.marketing-popup-background .preference_ratio input[type="radio"]:disabled + .radio-label:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
}
.marketing-popup-background .preference_ratio input[type="radio"] + .radio-label:empty:before {
          margin-right: 0;
}


.popup-backgroundImage .devicelist-container {
  width: 100%;
  float: left;
}
.popup-backgroundImage .list-left, .popup-backgroundImage .list-right {
  width: 50%;
  float: left;
  text-align: right;
}

.popup-backgroundImage .MuiDialogActions-root p {
  margin: 0 auto;
}
.popup-backgroundImage .MuiDialogContent-root {
  background: #FFFFFF;
  border: 1px solid #E8EEFA;
  box-sizing: border-box;
  border-radius: 4px;
}

.popup-backgroundImage .tagline {
  font-family: Hind, Sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 21px;
letter-spacing: 0px;
text-align: left;

}

.popup-backgroundImage .devicelist-container .list-left p{
font-family: Open Sans, Sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 17px;
letter-spacing: 0em;
text-align: left;

}

.popup-backgroundImage .devicelist-container {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E8EEFA;
}

.popup-backgroundImage .devicelist-container .list-right {
  margin-top: 5px;
}

.popup-backgroundImage .devicelist-container .list-right button, button.deviceDisconnect{
background: #05112A;
color: #F7F9FD;
border: 1px solid #1C222E;}

.popup-backgroundImage .devicelist-container .list-right button:hover, button.deviceDisconnect:hover{
background: no-repeat;
color: #95989e;
border: 1px solid #95989e;}

.popup-backgroundImage .MuiDialogContent-root:first-child {
  padding-top: 0px;
}
.popup-backgroundImage .closeButton .MuiSvgIcon-root, 
.popup-backgroundImage .arrowBackButton .MuiSvgIcon-root {
  width: 1.8em;
  height: 1.8em;
 }
.popup-backgroundImage .closeButton { 
  position: absolute;
  right: 8px;
  top: 8px;
  color: #333333;
}

.popup-backgroundImage .arrowBackButton { 
  position: absolute;
  left: 8px;
  top: 8px;
  color: #333333;
}

.alert-wrapper {
  width: 87%;
  margin-left: 100px;
  z-index: 1117;
  position: fixed;
}

.alert-wrapper .MuiAlert-standardSuccess {
  background-color: #2E2333;
  color: #ffffff;
  border-radius: 8px;
  padding: 0 16px;
}



.alert-wrapper .MuiAlert-icon svg {
  display: none;
}

.alert-wrapper h3 {
  margin: 4px;
  font-family: Hind, Sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #F7F9FD;
}

.alert-wrapper p {
  font-family: Hind, Sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #E8EEFA;
}

.alert-wrapper > * + * {
  margin-top: 20px;
}

.alert-wrapper .MuiAlert-icon {
  background-repeat: no-repeat;
  width: 30%;
  padding: 0 16px;
  background-size: cover;
}

.dialog-zindex{
  z-index: 1500 !important;
}

.vega-reader-header {
  top: auto !important;
}

#vega_left_panel > div {
  top: auto !important;
}

#vega_reader.BG-Black .ereader-qrcode-cta svg, #vega_reader.BG-Black .ereader-qrcode-cta-text {
  color: #FEFEFE
}